<template>
  <div class="animated fadeIn">
    <div class="row">
      <div class="col-md-6">
        <b-card :header="$t('message.details')">
          <table class="table2">
            <tr>
              <td>{{ $t("message.userName") }}</td>
              <td colspan="2">
                <b-form-input v-model="detail.userName" />
              </td>
              <td></td>
            </tr>
            <tr>
              <td>{{ $t("message.password") }}</td>
              <td colspan="2">
                <b-form-input v-model="detail.passWord" type="password" />
              </td>
              <td></td>
            </tr>
            <tr>
              <td>{{ $t("message.role") }}</td>
              <td colspan="2">
                <b-form-select v-model="detail.role" :options="role_select" />
              </td>
            </tr>
            <tr>
              <td>{{ $t("message.type") }}</td>
              <td colspan="2">
                <b-form-select v-model="detail.type" :options="userType" />
              </td>
            </tr>
            <!-- <tr>
              <td>{{ $t("message.titleName") }}</td>
              <td colspan="2"><b-form-select v-model="detail.titlename" :options="titlename"/></td>
            </tr>
            <tr>
              <td>{{ $t("message.nameTH") }}</td>
              <td><b-form-input placeholder="ชื่อ"  v-model="detail.FirstnameTH"/></td>
              <td><b-form-input placeholder="นามสกุล" v-model="detail.LastnameTH"/></td>
            </tr>
            <tr>
              <td>{{ $t("message.nameEN") }}</td>
              <td><b-form-input placeholder="First Name" v-model="detail.FirstnameEN"/></td>
              <td><b-form-input placeholder="Last Name" v-model="detail.LastnameEN"/></td>
            </tr>
            <tr>
              <td>{{ $t("message.idCard") }}</td>
              <td colspan="2"><b-form-input v-model="detail.idCard"/></td><td></td>
            </tr>
            <tr>
              <td>{{ $t("message.refNumber") }}</td>
              <td colspan="2"><b-form-input v-model="detail.tell"/></td><td></td>
            </tr>
            <tr>
              <td>{{ $t("message.email") }}</td>
              <td colspan="2"><b-form-input v-model="detail.email"/></td><td></td>
            </tr>-->
          </table>
        </b-card>
      </div>
      <div class="col-md-6">
        <b-card :header="$t('message.address')">
          <!-- <table class="table2">
            <tr>
              <td><b-form-input :placeholder="$t('message.addressName')" v-model="address.name"/></td>
              <td><b-form-input :placeholder="$t('message.roomNum')" v-model="address.roomNum"/></td>
              <td><b-form-input :placeholder="$t('message.floor')" v-model="address.floor"/></td>  
            </tr>
            <tr>
              <td><b-form-input :placeholder="$t('message.addressNum')" v-model="address.number"/></td>
              <td><b-form-input :placeholder="$t('message.villeageNum')" v-model="address.villeageNum"/></td>
              <td><b-form-input :placeholder="$t('message.alley')" v-model="address.alley"/></td>  
            </tr>
          </table>
          <table class="table2">
            <tr>
              <td><b-form-input :placeholder="$t('message.road')" v-model="address.road"/></td>
            </tr>
          </table>
          <table class="table2">
            <tr>
              <td><b-form-input :placeholder="$t('message.province')" v-model="address.province"/></td>
              <td><b-form-input :placeholder="$t('message.district')" v-model="address.district"/></td>
              <td><b-form-input :placeholder="$t('message.subDistrict')" v-model="address.sub_district"/></td>
            </tr>
          </table>
          <table class="table2">
            <tr>
              <td><b-form-input :placeholder="$t('message.postcode')" v-model="address.postcode"/></td> 
            </tr>
          </table>
          <table class="table2">
            <tr>
              <td><b-form-input :textarea=true :rows="10" placeholder="ที่อยู่จัดส่งเอกสาร" v-model="address.province"/></td>
            </tr>
            <tr>
              <td>
                <b-form-checkbox value="1">ใช้ที่อยู่ที่เดียวกับบัตรประชาชน</b-form-checkbox>
              </td>   
            </tr>
          </table> -->
        </b-card>
      </div>
    </div>
    <b-card>
      <div align="right">
        <b-button variant="danger" :active="true" size="lg">{{ $t('message.cancel') }}</b-button>&nbsp;
        <b-button
          variant="primary"
          :active="true"
          size="lg"
          @click="save()"
        >{{ $t('message.create') }}</b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import webServices from "../../script";
export default {
  name: "NewUser",
  data() {
    return {
      detail: {
        role: null,
        titlename: null,
        type: null
      },
      titlename: [
        { value: null, text: "Select title" },
        { value: "mr", text: "Mr. นาย" },
        { value: "ms", text: "Miss. นางสาว" },
        { value: "mrs", text: "Mrs. นาง" }
      ],
      address: {},
      role_select: [
        { value: null, text: "Select type" },
        { value: "admin", text: "admin" },
        { value: "dealer", text: "dealer" },
        { value: "owner", text: "owner" }
      ],
      userType: [
        { value: null, text: "Select type" },
        { value: "admin", text: "admin" },
        { value: "wonderwash", text: "wonderwash" },
        { value: "oem", text: "oem" },
        { value: "callcenter", text: "callcenter" },
        { value: "other", text: "อื่นๆ" }
      ]
    };
  },
  methods: {
    save() {
      this.detail.config = { language: "th" };
      webServices
        .createUser(this.detail)
        .then(res => {
          if (res.data.success) {
            // console.log(res.data)
            this.$toast.success({
              title: "SUCCESS",
              message: "User has been created"
            });
            this.$router.push("/user/users");
          } else {
            const error = webServices.showError(res.data);
            this.$toast.error({
              title: "ERROR",
              message: JSON.stringify(error)
            });
          }
        })
        .catch(err => {
          console.log(err);
          console.log("error @new user");
          this.$toast.error({
            title: "ERROR",
            message: "เกิดข้อผิดพลาด"
          });
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
        });
    }
  },
  mounted() {}
};
</script>

<style>
.table2 {
  width: 100%;
  border: none;
}
.table2 td,
.table2 th {
  padding: 8px;
}
</style>